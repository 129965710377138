import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { AvatarState } from 'components/Avatar'

export type Attachment = {
  type: 'link' | 'terms' | 'personal'
  data?: AttachmentLink
}

export type AttachmentLink = {
  url: string
  title: string
  description?: string
}

export type Message = {
  type: string
  time: Date
  from: 'bot' | 'user'
  text: string
  answers?: string[]
  attachments?: Attachment[]
}

export type State = {
  messages: Message[]
  addMessage: (question: Message) => void
  clearDialog: (
    messages?: Message[],
    sessionId?: string | null,
    answers?: string[],
    dialogContext?: number[]
  ) => void
  sessionId: string | null
  setSessionId: (id: string) => void
  reportId: string
  setReportId: (id: string) => void
  dialogContext: number[]
  setDialogContext: (dialogContext: number[]) => void
  isTyping: boolean
  setIsTyping: (isTyping: boolean) => void
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  isSpeaking: boolean
  setIsSpeaking: (isSpeaking: boolean) => void
  cancelAnswers: boolean
  setCancelAnswers: (cancelAnswers: boolean) => void
  showMenu: boolean
  setShowMenu: (showMenu: boolean) => void
  menuPage: string | null
  setMenuPage: (menuPage: string | null) => void
  showPrompt: string | null
  setShowPrompt: (showPrompt: string | null) => void
  showSpinner: boolean
  setShowSpinner: (showSpinner: boolean) => void
  answers: string[] | null
  avatarState: AvatarState | null
  setAvatarState: (state: AvatarState | null) => void
}

const initialMessage: Message[] = [
  {
    type: 'init',
    text: 'Hej, jeg hedder Vigo (den virtuelle guide til overvægt). Hvis du har lyst til at svare på op til syv spørgsmål, så kan jeg hjælpe dig med at finde den information om overvægt og vægttab, der er relevant for netop dig. Klik på "Start" for at begynde.',
    time: new Date(),
    from: 'bot',
  },
]

const initialAnswers: string[] = ['Start']

const initialDialogContext: number[] = []

export const useStore = create<State>()(
  devtools(
    persist(
      (set) => ({
        messages: initialMessage,
        addMessage: (message) =>
          set((state) => ({
            messages: state.messages.concat(message),
            answers: message.answers ?? null,
          })),
        clearDialog: (
          messages = initialMessage,
          sessionId = null,
          answers = initialAnswers,
          dialogContext = initialDialogContext
        ) =>
          set({
            messages,
            sessionId: sessionId ?? null,
            answers,
            dialogContext,
          }),
        sessionId: null,
        setSessionId: (sessionId) => set({ sessionId }),
        reportId: '',
        setReportId: (reportId) => set({ reportId }),
        dialogContext: initialDialogContext,
        setDialogContext: (dialogContext: number[]) => set({ dialogContext }),
        isTyping: false,
        setIsTyping: (isTyping) => set({ isTyping }),
        isLoading: false,
        setIsLoading: (isLoading) => set({ isLoading }),
        isSpeaking: false,
        setIsSpeaking: (isSpeaking) => set({ isSpeaking }),
        cancelAnswers: false,
        setCancelAnswers: (cancelAnswers) => set({ cancelAnswers }),
        showMenu: false,
        setShowMenu: (showMenu) => set({ showMenu }),
        menuPage: null,
        setMenuPage: (menuPage) => set({ menuPage }),
        showPrompt: null,
        setShowPrompt: (showPrompt) => set({ showPrompt }),
        showSpinner: false,
        setShowSpinner: (showSpinner) => set({ showSpinner }),
        answers: initialAnswers,
        avatarState: null,
        setAvatarState: (avatarState) => set({ avatarState }),
      }),
      {
        name: 'chatbot-state',
        getStorage: () => sessionStorage,
        partialize: (state) => ({
          messages: state.sessionId ? state.messages : initialMessage,
          sessionId: state.sessionId,
          reportId: state.reportId,
          answers: state.sessionId ? state.answers : initialAnswers,
          dialogContext: state.sessionId
            ? state.dialogContext
            : initialDialogContext,
        }),
      }
    )
  )
)

import React from 'react'

import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import theme from 'style/theme'
import { ThemeProvider } from 'styled-components'

import App from './components/App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(
  document.getElementById('vigo-bot') as HTMLElement
)

const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

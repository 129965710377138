type DigitalData = {
  event: string
  data: {
    page: {
      customEvents: string[]
      country: string
      readTime: string
      section: string
      language: string
      pageName: string
      breadcrumbs: string
      type: string
    }
    link?: Link
  }
}

type Link = {
  linkName: string
  linkURL: string
}

declare const digitalData: DigitalData[] | undefined

const track = (
  triggerEvent: 'Click',
  customEvents: string[] = [],
  link?: Link,
  country = 'denmark',
  readTime = '',
  section = '',
  language = 'da',
  pageName = '',
  breadcrumbs = '',
  type = 'content-page'
) => {
  try {
    if (digitalData) {
      const data: DigitalData['data'] = {
        page: {
          customEvents,
          country,
          readTime,
          section,
          language,
          pageName,
          breadcrumbs,
          type,
        },
      }

      if (link) {
        data.link = link
      }

      digitalData.push({
        event: triggerEvent,
        data,
      })
    }
  } catch (e) {
    console.log('Tracking failed', e)
  }
}

export default track

export const trackAnchor = (
  a: HTMLAnchorElement,
  eventName = 'Chatbot Link Clicked'
) => {
  const linkURL = a.getAttribute('href')
  const linkName = a.textContent?.trim()

  if (!linkURL || !linkName) return

  track('Click', [eventName], { linkName, linkURL })
}

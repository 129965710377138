import { FC } from 'react'

import styled from 'styled-components'

import useDialog from 'api/useDialog'

const Terms: FC = () => {
  const { setMenuPage } = useDialog()
  return (
    <Wrapper>
      <a
        href="/privacy"
        onClick={(e) => {
          e.preventDefault()
          setMenuPage('terms')
        }}
      >
        Vis betingelser for anvendelse →
      </a>
    </Wrapper>
  )
}

export default Terms

const Wrapper = styled.div`
  display: block;
  position: relative;
  padding: 1.2rem;
  margin-top: 0.9375rem;
  margin-left: 1.5rem;
  align-self: flex-start;

  border-radius: 1.875rem;
  border-top-left-radius: 0.3125rem;

  background-color: ${({ theme }) => theme.colors.tones.light};
  color: #fff;

  text-decoration: none;

  a {
    font-size: 1rem;

    display: block;
    text-decoration: none;
    color: #000;
    font-weight: 500;
  }
`

import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

import media from 'utils/MediaQueries'

const GlobalStyle = createGlobalStyle`
  ${reset}

  html, html #app {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 200;
    font-size: 14px;

    ${media.greaterThan('mobile')`
      font-size: 16px;
    `}
  }

  body, body #app {
    line-height: 1.375;
    color: #000;
  }

  .text {
    font-size: inherit;

    a {
      text-decoration: inherit;
    }
  }

  main a:hover {
    opacity: 1;
  }
`

export default GlobalStyle

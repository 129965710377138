import { Section } from 'api/useText'
import { FC } from 'react'
import styled from 'styled-components'
import { trackAnchor } from 'utils/track'
import useHtmlClickHandler from 'utils/useHtmlClickHandler'

export interface RichTextSection extends Section {
  content: string | null
}

export const isRichText = (s: Section): s is RichTextSection => {
  return s.type === 'RICH_TEXT'
}

type Props = {
  section: RichTextSection
  index: number
}

const RichText: FC<Props> = ({ section, index }) => {
  const { ref: htmlContent } = useHtmlClickHandler<HTMLDivElement>(trackAnchor)

  if (!section.content) return null

  return (
    <Text
      ref={htmlContent}
      key={index}
      dangerouslySetInnerHTML={{ __html: section.content }}
    />
  )
}

export default RichText

export const Text = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1rem;
  }

  h4 {
    font-size: 0.9rem;
  }

  h5 {
    font-size: 0.8rem;
  }

  h6 {
    font-size: 0.7rem;
  }

  p {
    margin-bottom: 1.5em !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  strong {
    font-weight: 500;
  }

  em {
    font-style: italic;
  }
`

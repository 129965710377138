import { FC } from 'react'

type Props = {}

const Logo: FC<Props> = () => {
  return (
    <svg
      width="123"
      height="50"
      viewBox="0 0 123 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.7973 31.5145L60.3462 8.66089H63.5341L67.0847 19.925C68.0723 23.0163 68.9099 25.7948 69.5225 28.4732H69.585C70.2226 25.8323 71.1727 22.9412 72.1979 19.9625L76.0609 8.67341H79.2114L71.0477 31.527H67.7973V31.5145Z"
        fill="#1E386A"
      />
      <path
        d="M84.3622 10.4881C84.3997 11.5019 83.6496 12.3154 82.4619 12.3154C81.4117 12.3154 80.6616 11.5019 80.6616 10.4881C80.6616 9.43681 81.4367 8.62329 82.5244 8.62329C83.6496 8.62329 84.3622 9.43681 84.3622 10.4881ZM81.0367 31.5144V15.1064H84.0121V31.5144H81.0367V31.5144Z"
        fill="#1E386A"
      />
      <path
        d="M103.09 15.1062C103.027 16.2952 102.952 17.6218 102.952 19.6118V29.1362C102.952 32.9034 102.202 35.2063 100.614 36.6331C99.0267 38.1224 96.7138 38.598 94.651 38.598C92.6882 38.598 90.5129 38.1224 89.2002 37.2464L89.9504 34.9685C91.038 35.6444 92.7257 36.2576 94.7635 36.2576C97.814 36.2576 100.052 34.6681 100.052 30.5255V28.6982H99.9893C99.0767 30.2251 97.3139 31.4391 94.776 31.4391C90.713 31.4391 87.8 27.9848 87.8 23.4416C87.8 17.8847 91.4256 14.7307 95.1886 14.7307C98.039 14.7307 99.5892 16.2201 100.302 17.5843H100.364L100.502 15.1062H103.09ZM100.014 21.5768C100.014 21.0636 99.9768 20.6256 99.8393 20.2251C99.3017 18.4979 97.839 17.0711 95.6762 17.0711C92.8258 17.0711 90.8005 19.4741 90.8005 23.2789C90.8005 26.4954 92.4257 29.1738 95.6387 29.1738C97.4639 29.1738 99.1267 28.0223 99.7768 26.1199C99.9518 25.6068 100.014 25.0311 100.014 24.5305V21.5768V21.5768Z"
        fill="#1E386A"
      />
      <path
        d="M122.743 23.1666C122.743 29.2367 118.542 31.8775 114.579 31.8775C110.141 31.8775 106.715 28.6234 106.715 23.4294C106.715 17.935 110.303 14.7185 114.841 14.7185C119.555 14.731 122.743 18.1478 122.743 23.1666ZM109.728 23.3418C109.728 26.9338 111.791 29.6497 114.704 29.6497C117.554 29.6497 119.68 26.9713 119.68 23.2792C119.68 20.5007 118.292 16.9713 114.766 16.9713C111.253 16.9713 109.728 20.2254 109.728 23.3418Z"
        fill="#1E386A"
      />
      <path
        d="M49.9072 21.8773C49.9072 26.7084 48.2069 31.3016 45.1315 34.806C43.5562 36.5957 41.706 38.01 39.6307 39.0113C37.4054 40.0876 35.03 40.6258 32.5922 40.6258H30.6169L15.0147 50V40.4631C13.3894 40.2253 11.8017 39.7497 10.2765 39.0113C8.20117 38.01 6.33841 36.5957 4.77568 34.806C1.70024 31.3016 0 26.7209 0 21.8773C0 17.0463 1.70024 12.4531 4.77568 8.94868C6.35091 7.15895 8.20117 5.74468 10.2765 4.74343C12.4518 3.69211 14.7646 3.15394 17.1525 3.12891C17.1525 1.40175 20.6405 0 24.9536 0C29.2667 0 32.7547 1.40175 32.7547 3.12891C35.1425 3.15394 37.4554 3.69211 39.6307 4.74343C41.706 5.74468 43.5687 7.15895 45.1315 8.94868C48.2069 12.4531 49.9072 17.0338 49.9072 21.8773ZM33.2298 32.6533C38.168 32.6533 42.1811 27.8223 42.1811 21.8773C42.1811 15.9324 38.168 11.1014 33.2298 11.1014H16.6524C11.7142 11.1014 7.6886 15.9324 7.6886 21.8773C7.6886 27.8223 11.7017 32.6533 16.6524 32.6533H33.2298V32.6533Z"
        fill="#CCDFEC"
      />
      <path
        d="M14.9646 21.8773C14.9646 20.3254 16.2148 19.0613 17.765 19.0613C19.3152 19.0613 20.5779 20.3128 20.5779 21.8773C20.5779 23.4292 19.3152 24.6933 17.765 24.6933C16.2148 24.6808 14.9646 23.4292 14.9646 21.8773Z"
        fill="#1E386A"
      />
      <path
        d="M29.3167 21.8773C29.3167 20.3254 30.5668 19.0613 32.1171 19.0613C33.6673 19.0613 34.93 20.3128 34.93 21.8773C34.93 23.4292 33.6673 24.6933 32.1171 24.6933C30.5668 24.6808 29.3167 23.4292 29.3167 21.8773Z"
        fill="#1E386A"
      />
    </svg>
  )
}

export default Logo

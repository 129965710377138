import { useEffect, useRef } from 'react'

const useHtmlClickHandler = <RefType extends HTMLElement>(
  onClick: (anchor: HTMLAnchorElement) => void | boolean
) => {
  const htmlContent = useRef<RefType>(null)

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement
    const anchor = target.closest('a')
    if (!anchor) {
      return
    }

    return onClick(anchor)
  }

  useEffect(() => {
    if (!htmlContent.current) return
    const links = htmlContent.current.querySelectorAll('a')
    links.forEach((link) => {
      link.addEventListener('click', handleClick)
    })

    return () => {
      links.forEach((link) => {
        link.removeEventListener('click', handleClick)
      })
    }
  })

  return {
    ref: htmlContent,
  }
}

export default useHtmlClickHandler

import { Section } from 'api/useText'
import { FC, useState } from 'react'
import styled from 'styled-components'

import { AnimatePresence, motion } from 'framer-motion'
import { trackAnchor } from 'utils/track'
import useHtmlClickHandler from 'utils/useHtmlClickHandler'
import { Text } from './RichText'

export interface AccordionSection extends Section {
  content: {
    title: string
    content: string | null
  }
}

export const isAccordion = (s: Section): s is AccordionSection => {
  return s.type === 'ACCORDION'
}

type Props = {
  section: AccordionSection
  index: number
}

const Accordion: FC<Props> = ({ section, index }) => {
  const [expanded, setExpanded] = useState(false)
  const { ref: htmlContent } = useHtmlClickHandler<HTMLDivElement>(trackAnchor)

  const key = `accordion-${index}`

  if (!section.content || !section.content.content) return null

  return (
    <AccordionComponent key={key} className={expanded ? 'active' : ''}>
      <TitleWrapper
        onClick={(e) => {
          e.preventDefault()
          expanded ? setExpanded(false) : setExpanded(true)
        }}
        href="#"
      >
        <Title>{section.content.title}</Title>
        <Arrow />
      </TitleWrapper>
      <AnimatePresence>
        {expanded && (
          <ContentWrapper
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
          >
            <Content
              dangerouslySetInnerHTML={{ __html: section.content.content }}
              ref={htmlContent}
            />
          </ContentWrapper>
        )}
      </AnimatePresence>
    </AccordionComponent>
  )
}

export default Accordion

const AccordionComponent = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.tones.light};

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.tones.light};
  }
`

const TitleWrapper = styled.a`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px 0;
  text-decoration: none;
  color: #000;
`

const Title = styled.h3`
  flex: 1;
  font-weight: 500;
`

const Arrow = styled.span`
  all: unset;
  right: 15px;
  top: 15px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.menu};
  box-sizing: border-box;
  margin-right: 5px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  transition: transform 0.5s;

  transform: rotate(90deg);

  .active & {
    transform: rotate(-90deg);
  }

  &:after,
  &:before {
    position: relative;
    content: '';
    width: 6px;
    height: 2px;
    left: 1px;
    background-color: ${({ theme }) => theme.colors.secondary};

    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
    margin-top: 2px;
  }
`

const ContentWrapper = styled(motion.div)`
  overflow: hidden;
`

const Content = styled(Text)`
  padding: 10px 0 20px;
`

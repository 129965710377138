import { FC } from 'react'

import { motion } from 'framer-motion'
import styled from 'styled-components'
import theme from 'style/theme'

import { AvatarState } from './'

const Dots: FC<Props> = ({ state }) => {
  return (
    <Wrapper>
      <DotsWrapper
        animate={{
          opacity: states[state].visible ? 1 : 0,
        }}
      >
        {Array(3)
          .fill(null)
          .map((_v, index) => (
            <Dot
              key={`dot-${index}`}
              animate={{
                backgroundColor: states[state].color,
                y: states[state].y,
                x: states[state].x,
              }}
              transition={{
                duration: states[state].duration,
                delay: index * states[state].delayFactor,
                scaleX: {
                  repeat: Infinity,
                },
                scaleY: {
                  repeat: Infinity,
                },
                x: {
                  repeat: Infinity,
                  repeatDelay: states[state].repeatDelay,
                  duration: states[state].duration,
                },
                y: {
                  repeat: Infinity,
                  repeatDelay: states[state].repeatDelay,
                  duration: states[state].duration,
                },
                opacity: {
                  repeat: Infinity,
                },
                backgroundColor: {
                  duration: 0.5,
                },
              }}
            />
          ))}
      </DotsWrapper>
    </Wrapper>
  )
}

export default Dots

const states = {
  idle: {
    visible: false,
    duration: 5,
    opacity: [0, 0],
    color: theme.colors.primary,
    y: [0, 0],
    x: [0, 0],
    repeatDelay: 0,
    delayFactor: 0.5,
  },
  listening: {
    visible: false,
    duration: 5,
    opacity: [0],
    color: theme.colors.primary,
    y: [0, 0, 0],
    x: [0, 0, 0],
    repeatDelay: 0,
    delayFactor: 0.5,
  },
  thinking: {
    visible: true,
    duration: 2,
    opacity: [0, 1, 1],
    color: theme.colors.primary,
    x: [-3, 3, -3],
    y: [0, 0, 0],
    repeatDelay: 0,
    delayFactor: 0.05,
  },
  speaking: {
    visible: true,
    duration: 1,
    opacity: [0, 0],
    color: theme.colors.secondary,
    x: [0, 0, 0],
    y: [0, -5, 2, 0],
    repeatDelay: 1,
    delayFactor: 0.1,
  },
  spoken: {
    visible: false,
    duration: 1,
    opacity: [0, 0],
    color: theme.colors.secondary,
    x: [0, 0, 0],
    y: [0, -5, 2, 0],
    repeatDelay: 1,
    delayFactor: 0.1,
  },
}

export type DotsState = keyof typeof states

type Props = {
  state: AvatarState
}

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 40px;
`

const DotsWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 25px;
  width: 51px;
  border-radius: 20px;
  background-color: ${theme.colors.tones.light};
  padding: 0 13px;
  box-sizing: border-box;
`

const Dot = styled(motion.div)`
  height: 5px;
  width: 5px;
  border-radius: 50%;
`

import { FC, useEffect, useRef } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

import media from 'utils/MediaQueries'

import useDialog from 'api/useDialog'

import Avatar, { AvatarState } from 'components/Avatar'

import BotMessage from './BotMessage'
import UserMessage from './UserMessage'

type Props = {}

const Messages: FC<Props> = () => {
  const { isLoading, messages, isTyping, setAvatarState, isSpeaking } =
    useDialog()
  const messagesRef = useRef<null | HTMLDivElement>(null)

  let avatarState: AvatarState | null = null

  if (isTyping) {
    avatarState = 'listening'
  }

  if (isSpeaking) {
    avatarState = 'speaking'
  }

  if (isLoading) {
    avatarState = 'thinking'
  }

  useEffect(() => {
    setAvatarState(avatarState)
  }, [avatarState, setAvatarState])

  useEffect(() => {
    // Scroll to bottom every time messages change.
    setTimeout(
      () => {
        messagesRef.current?.scroll({
          top:
            messagesRef.current?.scrollHeight -
            messagesRef.current?.clientHeight,
          behavior: 'smooth',
        })
      },
      avatarState === 'listening' ? 300 : 100
    )
  }, [avatarState, messages])

  return (
    <Wrapper ref={messagesRef}>
      {messages.map((message, index) => {
        if (
          index === messages.length - 1 &&
          (isSpeaking || isLoading) &&
          message?.from !== 'user'
        )
          return null

        const messageDiv =
          message?.from === 'user' ? (
            <UserMessage message={message} />
          ) : (
            <BotMessage
              message={message}
              last={index === messages.length - 1}
            />
          )

        return (
          <AnimatePresence key={`message-${index}`}>
            <MessageMotion
              initial={{
                opacity: 0,
                scale: 0.95,
              }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              exit={{
                opacity: 0,
                scale: 1,
              }}
              transition={{ delay: 0.3, bounce: false }}
              type={message?.from}
              key={index}
            >
              {messageDiv}
            </MessageMotion>
          </AnimatePresence>
        )
      })}
      <AnimatePresence>
        {avatarState && (
          <AvatarWrapper
            initial={{
              paddingTop: 0,
            }}
            animate={{
              paddingTop: 55,
            }}
            exit={{
              paddingTop: 0,
            }}
          >
            <Avatar state={avatarState} />
          </AvatarWrapper>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}

export default Messages

const Wrapper = styled.div`
  padding: 0 1.5625rem 0;
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  ${media.greaterThan('mobile')`
    margin: 0 1.5625rem;
  `}
`

const MessageMotion = styled(motion.div)<{ type?: string }>`
  align-self: ${({ type }) => (type === 'user' ? 'flex-end' : 'flex-start')};
`

const AvatarWrapper = styled(motion.div)`
  position: relative;
  overflow: hidden;

  > div {
    position: absolute;
    top: 1.875rem;
  }
`

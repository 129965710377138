import { useEffect } from 'react'

import { useStore } from 'store'
import GlobalStyle from 'style/GlobalStyle'
import styled from 'styled-components'

import { reportParam } from 'utils/getReportUrl'
import media from 'utils/MediaQueries'

import Logo from 'components/Logo'

import Input from './Input'
import Menu from './Menu'
import Messages from './Messages'
import Spinner from './Spinner'

function App() {
  const reportId = getReportIdFromUrl()
  const { setMenuPage, setShowMenu, setReportId } = useStore(
    ({ setMenuPage, setShowMenu, setReportId }) => ({
      setMenuPage,
      setShowMenu,
      setReportId,
    })
  )
  useEffect(() => {
    if (reportId) {
      setReportId(reportId)
      setMenuPage('personal')
    }
  }, [reportId, setMenuPage, setShowMenu, setReportId])
  return (
    <Wrapper>
      <GlobalStyle />
      <Content>
        <Header>
          <WelcomeText>
            Velkommen til <strong>VIGO</strong>
          </WelcomeText>
          <Logo />
        </Header>
        <Messages />
        <Input />
      </Content>
      <Menu />
      <Spinner />
    </Wrapper>
  )
}

export default App

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
`
const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  height: 80vh;
  min-height: 550px;

  ${media.greaterThan('mobile')`
    min-height: 600px;
    max-height: 700px;
  `}
`

const Header = styled.div`
  margin: 0;
  padding: 1.25rem;
  font-size: 1.75rem;
  text-align: center;
  font-style: italic;
  font-weight: 200;
  color: ${({ theme }) => theme.colors.secondary};

  svg {
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    height: 30px;
    width: auto;
  }

  strong {
    font-weight: 700;
  }

  ${media.lessThan('tablet')`
    height: 1.625rem;
  `}
`

const WelcomeText = styled.h1`
  margin: 0;
  padding: 0;

  ${media.lessThan('tablet')`
    display: none;
  `}
`

const getReportIdFromUrl = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const reportId = urlParams.get(reportParam)
  return reportId
}

import useText, { TextAssignment } from 'api/useText'
import Spinner from 'components/Spinner'
import { FC } from 'react'
import styled from 'styled-components'
import Accordion, { AccordionSection } from './Accordion'
import RichText, { RichTextSection } from './RichText'

type Props = {
  assignment: TextAssignment
}

const PageText: FC<Props> = ({ assignment }) => {
  const { isLoading, data } = useText(assignment)

  return (
    <TextPage>
      {isLoading && <Spinner show />}
      {!isLoading &&
        data?.sections &&
        data.sections.map((section, i) => {
          switch (section.type) {
            case 'RICH_TEXT':
              return (
                <RichText
                  section={section as RichTextSection}
                  index={i}
                  key={`section-${i}`}
                />
              )
            case 'ACCORDION':
              return (
                <Accordion
                  section={section as AccordionSection}
                  index={i}
                  key={`section-${i}`}
                />
              )
            default:
              return null
          }
        })}
    </TextPage>
  )
}

export default PageText

const TextPage = styled.div``

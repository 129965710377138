import { FC } from 'react'

import { Message } from 'store'
import styled from 'styled-components'

type Props = {
  message: Message
}

const UserMessage: FC<Props> = ({ message }) => {
  return <MessageWrapper>{message.text}</MessageWrapper>
}

export default UserMessage

const MessageWrapper = styled.div`
  padding: 1.1875rem 1.75rem;
  max-width: 500px;
  min-width: 50px;
  margin: 0.938rem 0;

  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border-radius: 1.875rem;

  text-align: center;
`

import { FC } from 'react'

import styled from 'styled-components'

import Dots from './Dots'
import Head from './Head'

export type AvatarState =
  | 'idle'
  | 'listening'
  | 'thinking'
  | 'speaking'
  | 'spoken'

type Props = { state: AvatarState }

const Avatar: FC<Props> = ({ state }) => {
  return (
    <Wrapper>
      <Head state={state} />
      <Dots state={state} />
    </Wrapper>
  )
}

export default Avatar

const Wrapper = styled.div`
  position: relative;
`

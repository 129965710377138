import useDialog from 'api/useDialog'
import { AnimatePresence, motion } from 'framer-motion'
import { FC, useState } from 'react'
import styled from 'styled-components'
import media from 'utils/MediaQueries'

type Props = {}

type Question = {
  title: string
  scale: Record<string, string>
  defaultScale: string
}

type SubmitMessage = {
  title: string
  message: string
  button: FeedbackButton
}

type FeedbackButton = {
  text: string
  onClick: () => void
}

const questions: Question[] = [
  {
    title:
      'I hvor høj grad hjalp chatbotten dig med at finde relevant information?',
    scale: {
      '1': 'Meget lav grad',
      '2': 'Lav grad',
      '3': 'Nogen grad',
      '4': 'Høj grad',
      '5': 'Meget høj grad',
    },
    defaultScale: '',
  },
  {
    title:
      'I hvor høj grad var chatbottens design og interface overskueligt og let at bruge?',
    scale: {
      '1': 'Meget lav grad',
      '2': 'Lav grad',
      '3': 'Nogen grad',
      '4': 'Høj grad',
      '5': 'Meget høj grad',
    },
    defaultScale: '',
  },
  {
    title: 'Hvad er din samlede oplevelse af chatbotten?',
    scale: {
      '1': 'Meget negativ',
      '2': 'Negativ',
      '3': 'Neutral',
      '4': 'Positiv',
      '5': 'Meget positiv',
    },
    defaultScale: '',
  },
]

const PageFeedback: FC<Props> = () => {
  const { sendFeedback, setMenuPage, setShowSpinner } = useDialog()
  let formStateObject: Record<string, string> = {}
  for (let i = 0; i < questions.length; i++) {
    const inputName = `menu-page-feedback-question-${i}`
    formStateObject[inputName] = questions[i].defaultScale
  }
  const [formState, setFormState] = useState(formStateObject)
  const [feedbackSubmitMessage, setFeedbackSubmitMessage] =
    useState<SubmitMessage | null>(null)

  return (
    <>
      <AnimatePresence>
        {feedbackSubmitMessage && (
          <FeedbackSubmittedWrapper
            initial={{
              opacity: 0,
              scale: 0.75,
            }}
            animate={{
              opacity: 1,
              scale: 1,
            }}
            exit={{
              opacity: 0,
              transition: {
                delay: 0.3,
              },
            }}
          >
            <MessageWrapper>
              <MessageTitle>{feedbackSubmitMessage.title}</MessageTitle>
              <Message>{feedbackSubmitMessage.message}</Message>
              {feedbackSubmitMessage.button && (
                <MessageButton
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    feedbackSubmitMessage.button.onClick()
                  }}
                >
                  {feedbackSubmitMessage.button.text}
                </MessageButton>
              )}
            </MessageWrapper>
          </FeedbackSubmittedWrapper>
        )}
      </AnimatePresence>
      {!feedbackSubmitMessage && (
        <FeedbackPage
          onSubmit={(e) => {
            e.preventDefault()
            setShowSpinner(true)
            sendFeedback(
              Object.values(formState).map((val) => {
                return parseInt(val)
              }),
              {
                onSuccess: () => {
                  setShowSpinner(false)
                  setFeedbackSubmitMessage({
                    title: 'Tak for din feedback',
                    message:
                      'Din feedback er nu sendt ind. Vi gennemgår løbende feedback med henblik på at forbedre og optimere servicen.',
                    button: {
                      text: 'Tilbage',
                      onClick: () => {
                        setMenuPage(null)
                      },
                    },
                  })
                },
                onError: () => {
                  setShowSpinner(false)
                  setFormState(formStateObject)
                  setFeedbackSubmitMessage({
                    title: 'Der skete en fejl',
                    message: 'Det lykkedes ikke at indsende din feedback.',
                    button: {
                      text: 'Prøv igen',
                      onClick: () => {
                        setFeedbackSubmitMessage(null)
                      },
                    },
                  })
                },
              }
            )
          }}
        >
          {questions.map((question, index) => {
            return (
              <QuestionWrapper key={`menu-page-feedback-question-${index}`}>
                <Title>{question.title}</Title>
                <Answers>
                  {Object.keys(question.scale).map((property, scaleIndex) => {
                    const inputName = `menu-page-feedback-question-${index}`

                    return (
                      <Answer
                        key={`${inputName}-${scaleIndex}`}
                        htmlFor={`${inputName}-${scaleIndex}`}
                      >
                        <Radio
                          type="radio"
                          value={property}
                          name={inputName}
                          id={`${inputName}-${scaleIndex}`}
                          defaultChecked={property === question.defaultScale}
                          onChange={(e) => {
                            setFormState({
                              ...formState,
                              [e.target.name]: e.target.value,
                            })
                          }}
                        />
                        <Dot>
                          <Checked />
                        </Dot>
                        <WeightText>{question.scale[property]}</WeightText>
                      </Answer>
                    )
                  })}
                  <Break />
                  <MinifiedWeightTexts>
                    <div>{Object.values(question.scale).shift()}</div>
                    <Arrows>
                      {Array(3)
                        .fill(null)
                        .map((_v, index) => (
                          <Arrow key={`minified-weight-arrow-${index}`} />
                        ))}
                    </Arrows>
                    <div>{Object.values(question.scale).pop()}</div>
                  </MinifiedWeightTexts>
                </Answers>
              </QuestionWrapper>
            )
          })}
          <Actions>
            <Button
              disabled={
                !!Object.values(formState).filter((val) => val === '').length
              }
            >
              Send feedback
            </Button>
          </Actions>
        </FeedbackPage>
      )}
    </>
  )
}

export default PageFeedback

const FeedbackSubmittedWrapper = styled(motion.div)`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #fff;
`

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -100px;
`

const MessageTitle = styled.div`
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.secondary};
`

const Message = styled.p`
  text-align: center;
  margin-bottom: 20px;
`

const MessageButton = styled.a`
  all: unset;
  cursor: pointer;
  padding: 15px 60px 13px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 30px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-size: 0.9em;
  transition: background-color 0.5s, color 0.5s;

  ${media.greaterThan('mobile')`
    font-size: 1em;
    padding: 15px 90px 13px;
  `}
`

const FeedbackPage = styled.form``

const QuestionWrapper = styled.div``

const Title = styled.h3`
  text-align: center;
  padding-bottom: 10px;
  font-size: 12px;

  ${media.greaterThan('mobile')`
    font-size: 16px;
    padding-bottom: 15px;
  `}
`

const Answers = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  margin-bottom: 25px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.tones.light};
  font-size: 12px;

  ${media.greaterThan('mobile')`
    margin-bottom: 15px;
    font-size: 14px;
  `}
`

const Answer = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
`

const Radio = styled.input`
  all: unset;
  visibility: hidden;
  width: 0;
  height: 0;

  &:checked + div > div {
    opacity: 1;
    scale: 1;
  }
`

const Dot = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  margin-bottom: 5px;

  ${media.greaterThan('mobile')`
    width: 40px;
    height: 40px;
  `}

  &:hover > div {
    opacity: 1;
    scale: 1;
  }
`

const Checked = styled.div`
  opacity: 0;
  width: 20px;
  height: 20px;
  scale: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: scale 0.3s, opacity 0.8s;

  ${media.greaterThan('mobile')`
    width: 30px;
    height: 30px;
  `}
`

const WeightText = styled.div`
  display: none;
  ${media.greaterThan('mobile')`
    display: block;
  `}
`

const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`

const MinifiedWeightTexts = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;

  & > * {
    flex: 1;
    padding: 0 5px;
  }

  & *:first-child {
    text-align: left;
  }

  & *:last-child {
    text-align: right;
  }

  ${media.greaterThan('mobile')`
    display: none;
  `}
`

const Arrows = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Arrow = styled.span`
  all: unset;
  right: 15px;
  top: 15px;
  width: 10px;
  height: 15px;
  box-sizing: border-box;
  scale: 0.8;

  ${media.greaterThan('mobile')`
    scale: 1;
  `}

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  transform: rotate(0deg);

  &:after,
  &:before {
    position: relative;
    content: '';
    width: 6px;
    height: 2px;
    left: 1px;
    background-color: ${({ theme }) => theme.colors.tones.dark};

    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
    margin-top: 2px;
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
`

const Button = styled.button`
  all: unset;
  cursor: pointer;
  padding: 15px 60px 13px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 30px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-size: 0.9em;
  transition: background-color 0.5s, color 0.5s;

  ${media.greaterThan('mobile')`
    font-size: 1em;
    padding: 15px 90px 13px;
  `}

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.colors.tones.light};
    color: ${({ theme }) => theme.colors.tones.dark};
  }
`

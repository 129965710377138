import { FC } from 'react'

import { motion } from 'framer-motion'
import styled from 'styled-components'
import theme from 'style/theme'

import { AvatarState } from './'

const Head: FC<Props> = ({ state, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <MouthBrainWrapper
        initial={false}
        animate={{
          rotate: states[state].rotate,
        }}
        transition={{
          type: 'spring',
          stiffness: 100,
          damping: 20,
        }}
      >
        <MouthBrain
          animate={{
            backgroundColor: states[state].color,
            scaleX: states[state].scaleX,
            scaleY: states[state].scaleY,
            y: states[state].y,
            x: states[state].x,
          }}
          transition={{
            duration: 8,
            scaleX: {
              repeat: Infinity,
            },
            scaleY: {
              repeat: Infinity,
            },
            x: {
              repeat: Infinity,
            },
            y: {
              repeat: Infinity,
            },
            backgroundColor: {
              duration: 0.5,
            },
          }}
        />
      </MouthBrainWrapper>
    </Wrapper>
  )
}

export default Head

const states = {
  idle: {
    rotate: '0deg',
    color: theme.colors.tones.light,
    scaleX: [1, 1],
    scaleY: [1, 1],
    y: [0, 0],
    x: [0, 0],
  },
  thinking: {
    rotate: '0deg',
    color: theme.colors.primary,
    scaleX: [1, 1],
    scaleY: [1, 1],
    y: [0, 0, 0],
    x: [-0.5, 0.5, -0.5],
  },
  listening: {
    rotate: '-90deg',
    color: theme.colors.tones.medium,
    scaleX: [1, 1.1, 1],
    scaleY: [1, 1.1, 1],
    y: [0, 0, 0],
    x: [0, 0, 0],
  },
  speaking: {
    rotate: '180deg',
    color: theme.colors.secondary,
    scaleX: [1, 1, 1],
    scaleY: [0.9, 1, 0.9],
    y: [0, 0, 0],
    x: [0, 0, 0],
  },
  spoken: {
    rotate: '180deg',
    color: theme.colors.secondary,
    scaleX: [1, 1, 1],
    scaleY: [1, 1, 1],
    y: [0, 0, 0],
    x: [0, 0, 0],
  },
}

type Props = {
  state: AvatarState
}

const Wrapper = styled.div`
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${theme.colors.tones.light};
`

const MouthBrainWrapper = styled(motion.div)`
  height: 18px;
  width: 18px;
  margin: 3.4px;
`

const MouthBrain = styled(motion.div)`
  height: 9px;
  width: 18px;
  border-radius: 9px 9px 0 0;
`

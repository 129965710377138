import useDialog from 'api/useDialog'
import { AnimatePresence, motion } from 'framer-motion'
import { FC } from 'react'
import styled from 'styled-components'
import media from 'utils/MediaQueries'

type Props = {
  id: string
  title: string
  summary?: string
  buttons: ButtonProps[]
  width?: number
}

type ButtonProps = {
  text: string
  style?: string
  onClick: () => void
}

const Prompt: FC<Props> = ({ id, title, summary, buttons, width }) => {
  const { showPrompt } = useDialog()
  return (
    <AnimatePresence>
      {showPrompt && showPrompt === id && (
        <Wrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <PromptWrapper width={width}>
            <Title>{title}</Title>
            <Summary>{summary}</Summary>
            <ButtonsWrapper>
              {buttons.map((button, index) => {
                return (
                  <Button
                    href="#"
                    key={`promptbutton-${id}-${index}`}
                    onClick={(e) => {
                      e.preventDefault()
                      button.onClick()
                    }}
                    className={button.style ? button.style : ''}
                  >
                    {button.text}
                  </Button>
                )
              })}
            </ButtonsWrapper>
          </PromptWrapper>
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

export default Prompt

const Wrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;

  ${media.greaterThan('mobile')`
    padding: 30px 50px;
  `}
`

const PromptWrapper = styled.div<{ width?: number }>`
  padding: 25px 35px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.primary};
  max-width: ${({ width }) => (width ? `${width}px` : 'auto')};
  box-sizing: border-box;

  ${media.greaterThan('mobile')`
    padding: 35px 45px;
  `}
`

const Title = styled.h2`
  text-align: center;
  color: #fff;
  font-size: 24px;
  padding: 10px 0 20px;

  ${media.greaterThan('mobile')`
    font-size: 28px;
  `}
`

const Summary = styled.div`
  color: #fff;
  line-height: 1.5em;
  font-size: 0.9em;
  text-align: center;

  ${media.greaterThan('mobile')`
    font-size: 1em;
  `}
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 30px;
`

const Button = styled.a`
  all: unset;
  cursor: pointer;
  padding: 15px 20px 13px;
  background-color: #fff;
  border-radius: 30px;
  text-decoration: none;
  color: #000;
  font-size: 0.9em;

  ${media.greaterThan('mobile')`
    font-size: 1em;
    padding: 15px 30px 13px;
  `}

  &.secondary {
    color: #fff;
    padding: 14px 30px 14px;
    background-color: transparent;
    border: 1px solid #fff;
  }
`

import { useQuery } from 'react-query'

import client from './client'

export type TextAssignment = 'TERMS' | 'PRIVACY_POLICY' | 'ABOUT'

export const sectionTypes = {
  RICH_TEXT: 'Rich text',
  ACCORDION: 'Accordion',
}

export interface Section {
  type: keyof typeof sectionTypes
  content: unknown
}

const useText = (textAssignment: TextAssignment) =>
  useQuery<{ sections: Section[] }>(
    `text/${textAssignment}`,
    async () => (await client.get(`/text/${textAssignment}`)).data
  )

export default useText

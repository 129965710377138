import { FC, useCallback, useEffect, useState } from 'react'

import theme from 'style/theme'
import styled from 'styled-components'

import getReportUrl from 'utils/getReportUrl'

import useDialog from 'api/useDialog'
import useReport from 'api/useReport'

import Spinner from 'components/Spinner'

import Copy from 'components/icons/Copy'
import { trackAnchor } from 'utils/track'
import useHtmlClickHandler from 'utils/useHtmlClickHandler'

let timeout: ReturnType<typeof setTimeout>

const PagePersonal: FC = () => {
  const [copyStatus, setCopyStatus] = useState<'idle' | 'error' | 'success'>(
    'idle'
  )
  const { reportId } = useDialog()
  const { data, isLoading } = useReport(reportId)
  const content = data?.data

  const error = !content && !isLoading

  const reportUrl = getReportUrl(reportId)

  const { ref: htmlContent } = useHtmlClickHandler<HTMLDivElement>(trackAnchor)

  const resetCopyStatus = useCallback(() => {
    setCopyStatus('idle')
  }, [setCopyStatus])

  const copyReportUrl = useCallback(() => {
    if (!reportUrl) return

    setCopyStatus('idle')
    navigator.clipboard.writeText(reportUrl).then(
      () => {
        setCopyStatus('success')
        timeout = setTimeout(resetCopyStatus, 3000)
      },
      () => {
        setCopyStatus('error')
        timeout = setTimeout(resetCopyStatus, 3000)
      }
    )
  }, [reportUrl, setCopyStatus, resetCopyStatus])

  useEffect(() => {
    return () => clearTimeout(timeout)
  }, [])

  return (
    <PersonalPage>
      {isLoading && <Spinner show />}
      {!isLoading && content?.texts && content?.texts?.length > 0 && (
        <>
          <HTMLContent
            ref={htmlContent}
            dangerouslySetInnerHTML={{ __html: content?.texts.join('') }}
          />
          {reportUrl && (
            <LinkWrapper>
              <LinkHeader>Gem din personlige side</LinkHeader>
              <Link>
                <input
                  type="text"
                  readOnly
                  onFocus={(e) => e.target.select()}
                  onClick={(e) => e.preventDefault()}
                  onSelect={(e) => e.preventDefault()}
                  value={reportUrl}
                />
                <CopyButton
                  disabled={copyStatus !== 'idle'}
                  onClick={copyReportUrl}
                  color={
                    copyStatus === 'idle'
                      ? theme.colors.secondary
                      : copyStatus === 'error'
                      ? 'red'
                      : theme.colors.primary
                  }
                >
                  <Copy />
                </CopyButton>
              </Link>
              <LinkInstruction>
                Kopiér linket og gem det. Så kan du altid besøge din personlige
                side igen.
              </LinkInstruction>
            </LinkWrapper>
          )}
        </>
      )}
      {error && (
        <Error>
          Vi kunne desværre ikke finde din personlige side. Du kan starte en ny
          ved at lukke dette vindue.
        </Error>
      )}
    </PersonalPage>
  )
}

export default PagePersonal

const PersonalPage = styled.div``

/**
 * !IMPORTANT!
 * Changing the below CSS can have fatal consequences for historical reports
 * generated, as we are saving the HTML.
 *
 * Only change it, if you know what you are doing and you fully understand the
 * consequences.
 */
const HTMLContent = styled.div`
  margin-bottom: 3rem;

  p,
  a.vigo-link--box {
    margin-bottom: 1.5rem;
  }

  h3 {
    font-weight: 700;
    font-size: 1.375rem;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 0.5rem;
    margin-top: 3rem;
  }

  p:first-child + h3 {
    margin-top: 0;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  a.vigo-link--inline {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }

  a.vigo-link--box {
    display: block;
    position: relative;
    padding: 1rem;
    padding-right: calc(1.5rem + 60px);
    transition: 200ms;

    background-color: ${({ theme }) => theme.colors.tones.light};
    color: #fff;
    overflow: hidden;

    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 4px solid ${({ theme }) => theme.colors.secondary};

    text-decoration: none;

    &:before,
    &:after {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 30px;
      font-size: 10px;
      line-height: 1;
    }

    &:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 194.818 194.818' width='16' height='16' %3E%3Cpath fill='%23fff' d='M185.818 2.161h-57.04c-4.971 0-9 4.029-9 9s4.029 9 9 9h35.312l-86.3 86.3a9 9 0 0 0 6.364 15.364 8.975 8.975 0 0 0 6.364-2.636l86.3-86.3v35.313c0 4.971 4.029 9 9 9s9-4.029 9-9v-57.04a9 9 0 0 0-9-9.001z' /%3E%3Cpath fill='%23fff' d='M149 77.201a9 9 0 0 0-9 9v88.456H18v-122h93.778c4.971 0 9-4.029 9-9s-4.029-9-9-9H9a9 9 0 0 0-9 9v140a9 9 0 0 0 9 9h140a9 9 0 0 0 9-9V86.201a9 9 0 0 0-9-9z' /%3E%3C/svg%3E");
      right: auto;
      left: 0;
      border-bottom-left-radius: 8px;
      background-color: ${({ theme }) => theme.colors.secondary};

      opacity: 0;
      transform: translateX(-100%);
      transition: 200ms;
    }

    &:after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23001966' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-book-open'%3E%3Cpath d='M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z'%3E%3C/path%3E%3Cpath d='M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z'%3E%3C/path%3E%3C/svg%3E");
      right: 0.5rem;
    }

    &.vigo-link--box--knowledge {
      &:after {
        content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 384 512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23001966' d='M112.1 454.3c0 6.297 1.816 12.44 5.284 17.69l17.14 25.69c5.25 7.875 17.17 14.28 26.64 14.28h61.67c9.438 0 21.36-6.401 26.61-14.28l17.08-25.68c2.938-4.438 5.348-12.37 5.348-17.7L272 415.1h-160L112.1 454.3zM192 0C90.02 .3203 16 82.97 16 175.1c0 44.38 16.44 84.84 43.56 115.8c16.53 18.84 42.34 58.23 52.22 91.45c.0313 .25 .0938 .5166 .125 .7823h160.2c.0313-.2656 .0938-.5166 .125-.7823c9.875-33.22 35.69-72.61 52.22-91.45C351.6 260.8 368 220.4 368 175.1C368 78.8 289.2 .0039 192 0zM288.4 260.1c-15.66 17.85-35.04 46.3-49.05 75.89h-94.61c-14.01-29.59-33.39-58.04-49.04-75.88C75.24 236.8 64 206.1 64 175.1C64 113.3 112.1 48.25 191.1 48C262.6 48 320 105.4 320 175.1C320 206.1 308.8 236.8 288.4 260.1zM176 80C131.9 80 96 115.9 96 160c0 8.844 7.156 16 16 16S128 168.8 128 160c0-26.47 21.53-48 48-48c8.844 0 16-7.148 16-15.99S184.8 80 176 80z'/%3E%3C/svg%3E");
      }
    }

    &.vigo-link--box--podcast {
      &:after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23001966' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-headphones'%3E%3Cpath d='M3 18v-6a9 9 0 0 1 18 0v6'%3E%3C/path%3E%3Cpath d='M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z'%3E%3C/path%3E%3C/svg%3E");
      }
    }

    &.vigo-link--box--video {
      &:after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23001966' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-film'%3E%3Crect x='2' y='2' width='20' height='20' rx='2.18' ry='2.18'%3E%3C/rect%3E%3Cline x1='7' y1='2' x2='7' y2='22'%3E%3C/line%3E%3Cline x1='17' y1='2' x2='17' y2='22'%3E%3C/line%3E%3Cline x1='2' y1='12' x2='22' y2='12'%3E%3C/line%3E%3Cline x1='2' y1='7' x2='7' y2='7'%3E%3C/line%3E%3Cline x1='2' y1='17' x2='7' y2='17'%3E%3C/line%3E%3Cline x1='17' y1='17' x2='22' y2='17'%3E%3C/line%3E%3Cline x1='17' y1='7' x2='22' y2='7'%3E%3C/line%3E%3C/svg%3E");
      }
    }

    &:hover {
      padding-left: calc(1rem + 30px);
      padding-right: calc(1.5rem + 30px);

      &:before {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .vigo-link__title {
      display: block;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.2;
      color: ${({ theme }) => theme.colors.secondary};
    }

    .vigo-link__description {
      display: block;
      margin-top: 0.8rem;
      font-size: 0.8rem;
      font-weight: 100;
      line-height: 1.5;
      color: ${({ theme }) => theme.colors.text};
    }
  }
`

const LinkWrapper = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.tones.light};
  border-radius: 8px;
`

const LinkHeader = styled.h3`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 500;
`

const Link = styled.div`
  position: relative;
  margin: 1.2rem 0 1rem;

  input {
    all: unset;
    padding: 0.5rem;
    padding-left: calc(40px + 0.5rem);
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;

    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 100;
    font-family: inherit;
    color: ${({ theme }) => theme.colors.tones.darker};
  }
`

const CopyButton = styled.button<{ color: string }>`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;

  background-color: ${({ color }) => color};

  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryDark};

    &[disabled] {
      background-color: ${({ color }) => color};
    }
  }

  transition: 500ms;

  svg {
    display: block;
    width: 16px;
    height: auto;
  }
`

const LinkInstruction = styled.p`
  font-size: 0.8rem;
  margin-bottom: 0;
`

const Error = styled.div``

import { FC } from 'react'

import { Attachment } from 'store'
import styled from 'styled-components'

import Link from './Link'
import Personal from './Personal'
import Terms from './Terms'

type Props = {
  attachment: Attachment
}

const AttachmentComponent: FC<Props> = ({ attachment }) => {
  return <Wrapper>{renderAttachment(attachment)}</Wrapper>
}

const renderAttachment = (a: Attachment) => {
  switch (a.type) {
    case 'link':
      return <Link data={a.data} />
    case 'terms':
      return <Terms />
    case 'personal':
      return <Personal />
  }
}

export default AttachmentComponent

const Wrapper = styled.div``

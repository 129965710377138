import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import client from './client'

const useReport = (reportId: string) =>
  useQuery<AxiosResponse<{ texts: string[] }>>(`report/${reportId}`, () =>
    client.get(`/report/${reportId}`)
  )

export default useReport

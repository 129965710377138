import { FC, useRef, useState } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

import media from 'utils/MediaQueries'

import useDialog from 'api/useDialog'

import { MenuItemPage } from '../'
import PageFeedback from './Feedback'
import PagePersonal from './Personal'
import PageText from './Text'

type Props = {
  page: MenuItemPage
}

const contentResolver = (page?: MenuItemPage) => {
  switch (page?.type) {
    case 'text':
      if (!page.assignment) return null
      return (
        <PageText
          assignment={page.assignment}
          key={`${page.assignment}-${page.type}}`}
        />
      )

    case 'feedback':
      return <PageFeedback />

    case 'personal':
      return <PagePersonal />

    default:
      return <>Page type not supported!</>
  }
}

const MenuPage: FC<Props> = ({ page }) => {
  const { setMenuPage } = useDialog()
  const contentRef = useRef<HTMLDivElement>(null)
  const [topFade, setTopFade] = useState<boolean>(false)
  const [bottomFade, setBottomFade] = useState<boolean>(true)

  function scrollFade(elm: HTMLDivElement) {
    const scrollTop = elm.scrollTop
    const scrollBottom = scrollTop + elm.clientHeight
    const scrollHeight = elm.scrollHeight
    const clientHeight = elm.clientHeight
    setTopFade(scrollTop > 1 && scrollHeight > clientHeight)
    setBottomFade(
      scrollBottom + 1 < scrollHeight && scrollHeight > clientHeight
    )
  }

  return (
    <Page
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      transition={{
        duration: 1.2,
        ease: 'anticipate',
      }}
      className={`${page.type}`}
    >
      <BackButton
        exit={{
          rotate: 180,
          opacity: 0,
        }}
        transition={{
          rotate: {
            duration: 0.1,
          },
          opacity: {
            duration: 1,
          },
        }}
        onClick={(e) => {
          e.preventDefault()
          setMenuPage(null)
        }}
      >
        <ArrowPoint />
        <Arrow />
        <ArrowPoint />
      </BackButton>
      <Title dangerouslySetInnerHTML={{ __html: page.title }} />
      <ContentWrapper>
        <Content
          ref={contentRef}
          onScroll={(elm) => scrollFade(elm.currentTarget)}
          onClick={(e) => {
            const target = e.currentTarget
            setTimeout(() => {
              scrollFade(target)
            }, 200)
          }}
        >
          {contentResolver(page)}
        </Content>
        <AnimatePresence>
          {topFade && (
            <TopFade
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {bottomFade && (
            <BottomFade
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          )}
        </AnimatePresence>
      </ContentWrapper>
    </Page>
  )
}

export default MenuPage

const Page = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;

  padding: 20px 30px 60px;

  transition: padding 0.5s;
  border-radius: 30px;

  ${media.greaterThan('mobile')`
    padding: 20px 100px 60px;
  `}
  display: flex;
  flex-direction: column;

  &.feedback {
    padding-bottom: 20px;
  }
`

const BackButton = styled(motion.button)`
  all: unset;
  cursor: pointer;
  position: absolute;
  left: 15px;
  top: 15px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.menu};
  box-sizing: border-box;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  transition: transform 0.5s;
`

const ArrowPoint = styled.span`
  position: relative;
  content: '';
  width: 9px;
  height: 2px;
  left: -6px;
  background-color: ${({ theme }) => theme.colors.secondary};

  transform: rotate(-45deg);

  &:last-child {
    transform: rotate(45deg);
    margin-top: 1px;
  }
`
const Arrow = styled.span`
  width: 16px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.secondary};
  margin-top: 1px;
`

const Title = styled.h2<{ subtle?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 30px;
  padding-bottom: 70px;

  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 28px;

  strong {
    font-weight: 700;
    font-style: italic;
  }

  .feedback & {
    padding-bottom: 40px;
  }

  .personal & {
    font-size: 14px;
    font-weight: 700;
    color: #b7b7b5;
    padding-bottom: 20px;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  overflow: hidden;
`

const Fade = styled(motion.div)`
  display: block;
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  .feedback & {
    display: none;
  }
`

const TopFade = styled(Fade)`
  top: 0;
  bottom: auto;
  transform: rotate(180deg);
`

const BottomFade = styled(Fade)``

const Content = styled.div`
  flex: 1;
  position: relative;
  overflow: auto;
  line-height: 1.5em;
  padding-right: 30px;

  .feedback & {
    padding-right: 0;
  }
`

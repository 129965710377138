import { FC } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import media from 'utils/MediaQueries'

import useDialog from 'api/useDialog'
import track from 'utils/track'

type Props = {}

const Input: FC<Props> = () => {
  const {
    sendMessage,
    isLoading,
    cancelAnswers,
    answers,
    messages,
    avatarState,
  } = useDialog()

  const showAnswers = answers?.length && !cancelAnswers && avatarState === null

  const messageType = messages[messages.length - 1]?.type

  const optionStyle = messageType === 'final' ? 'minimal' : 'default'

  return (
    <>
      <Wrapper>
        <AnimatePresence>
          {showAnswers && !isLoading && (
            <AnswersWrapper
              initial={{
                opacity: 0,
                scale: 0.95,
                pointerEvents: 'none',
              }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: {
                  delay: 0.5,
                  bounce: false,
                },
                pointerEvents: 'auto',
              }}
              exit={{
                opacity: 0,
                scale: 1,
                transition: {
                  delay: 0,
                  duration: 0.2,
                  bounce: false,
                },
                pointerEvents: 'none',
              }}
            >
              {answers?.map((option, index) => {
                return (
                  <Option
                    optionStyle={optionStyle}
                    onClick={() => {
                      // if the value of the button is "Start" call the "track" function
                      if (option === 'Start') {
                        track('Click', ['Chatbot Initiated'])
                      }
                      sendMessage(option)
                    }}
                    key={index}
                  >
                    {option}
                  </Option>
                )
              })}
            </AnswersWrapper>
          )}
        </AnimatePresence>
      </Wrapper>
    </>
  )
}

export default Input

const Wrapper = styled.div`
  position: relative;
  padding: 1.5625rem;

  ${media.greaterThan('mobile')`
    padding: 1.875rem 3.125rem 3.125rem;
  `}

  flex-grow: 0;
`

const AnswersWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
`

const Option = styled.button<{ optionStyle: 'minimal' | 'default' }>`
  all: unset;
  padding: 1.1875rem;
  min-width: 80px;
  border-radius: 1.875rem;
  text-align: center;
  cursor: pointer;
  transition: background-color 300ms;

  ${({ optionStyle }) =>
    optionStyle === 'default' &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: #fff;

      &:hover {
        background-color: ${({ theme }) => theme.colors.primaryDark};
      }
    `};

  ${({ optionStyle }) =>
    optionStyle === 'minimal' &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.colors.tones.darker};

      &:hover {
        background-color: ${({ theme }) => theme.colors.tones.light};
      }

      &:after {
        display: inline-block;
        content: '⟲';
        margin-left: 1rem;
        font-size: 1.5rem;
        line-height: 0;
      }
    `};
`

export const reportParam = 'vigo-report'

const getReportUrl = (reportId: string) => {
  if (!process.env.REACT_APP_FRONTEND_URL || !reportId) {
    return null
  }
  return `${process.env.REACT_APP_FRONTEND_URL}?${reportParam}=${reportId}`
}

export default getReportUrl

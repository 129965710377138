import { FC } from 'react'

import styled from 'styled-components'

import media from 'utils/MediaQueries'

import LinkIcon from 'components/icons/Link'

type Props = {
  data: any
}

const Link: FC<Props> = ({ data }) => {
  return (
    <Wrapper href={data.url ?? undefined} target="_blank">
      {data.title && <Title>{data.title}</Title>}
      {data.description && <Description>{data.description}</Description>}

      <LinkIconWrapper>
        <LinkIcon />
      </LinkIconWrapper>
    </Wrapper>
  )
}

export default Link

const Wrapper = styled.a`
  display: block;
  position: relative;
  padding: 1.2rem;
  margin-top: 0.9375rem;
  margin-left: 1.5rem;
  align-self: flex-start;

  border-radius: 1.875rem;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;

  background-color: ${({ theme }) => theme.colors.secondary};
  color: #fff;

  text-decoration: none;
`

const Title = styled.span`
  display: block;
  font-weight: 500;
  line-height: 1rem;
`

const Description = styled.span`
  margin-top: 1rem;
  font-size: 0.8rem;
  line-height: 1rem;
`

const LinkIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;

  top: 0;
  right: 1rem;
  bottom: 0;

  ${media.greaterThan('mobile')`
    padding:
  `}
`

import useDialog from 'api/useDialog'
import { FC } from 'react'
import styled from 'styled-components'

type Props = {}

const MenuToggle: FC<Props> = () => {
  const { showMenu, setShowMenu, menuPage, setMenuPage } = useDialog()

  return (
    <Toggle
      onClick={() => {
        setMenuPage(null)
        setTimeout(
          () => {
            setShowMenu(!showMenu)
          },
          menuPage ? 1000 : 0
        )
      }}
      className={showMenu ? 'close' : ''}
    >
      <Dot />
      <CenterDot />
      <Dot />
    </Toggle>
  )
}

export default MenuToggle

const Toggle = styled.button`
  all: unset;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.menu};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  box-sizing: border-box;

  &.close {
    flex-direction: column;
    justify-content: center;
  }
`

const Dot = styled.span`
  position: absolute;
  left: 8px;
  height: 4px;
  width: 4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.secondary};
  opacity: 1;
  transition: transform 0.5s ease, width 0.3s ease 0.5s, height 0.3s ease 0.6s;

  &:last-child {
    left: auto;
    right: 8px;
  }

  .close & {
    transition: width 0.3s ease, transform 0.5s ease 0.3s;
    content: '';
    width: 20px;
    height: 2px;
    border-radius: 0;
    background-color: ${({ theme }) => theme.colors.secondary};

    transform: rotate(45deg);

    &:last-child {
      transform: rotate(-45deg);
    }
  }
`

const CenterDot = styled(Dot)`
  position: absolute;
  top: calc(50% - 2px);
  left: calc(50% - 2px);
  transition: opacity 0.5s ease 0.8s;

  .close & {
    opacity: 0;
  }
`

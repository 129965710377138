import { useCallback } from 'react'

import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { Message, useStore } from 'store'

import apiClient from './client'

const fakeLoadingMax = 800
const fakeLoadingMin = 400
const speakingMax = 3000
const speakingMin = 500

const useDialog = () => {
  const {
    messages,
    addMessage,
    clearDialog,
    setSessionId,
    sessionId,
    reportId,
    setReportId,
    dialogContext,
    setDialogContext,
    isTyping,
    setIsTyping,
    isLoading,
    setIsLoading,
    isSpeaking,
    setIsSpeaking,
    cancelAnswers,
    setCancelAnswers,
    showMenu,
    setShowMenu,
    menuPage,
    setMenuPage,
    showPrompt,
    setShowPrompt,
    showSpinner,
    setShowSpinner,
    answers,
    avatarState,
    setAvatarState,
  } = useStore((state) => state)

  const { mutate: submitMessage, isLoading: mutationLoading } = useMutation(
    (answer: string) => {
      return apiClient.post<SendMessagePayload, SendMessageResponse>(
        '/message',
        { answer, dialogContext, sessionId }
      )
    },
    {
      onSuccess: (data, userMessage) => {
        setSessionId(data.data.sessionId ?? null)

        if (data.data.dialogContext) {
          setDialogContext(data.data.dialogContext)
        }

        if (data.data.reportId) {
          setReportId(data.data.reportId)
        }

        if (data.data.message) {
          if (data.data.message.type === 'init') {
            clearDialog([], data.data.sessionId, [])
          }
          const loadTimeout = Math.min(
            Math.max(userMessage.length * 80, fakeLoadingMin),
            fakeLoadingMax
          )
          const speakTimeout = Math.min(
            Math.max(data.data.message.text.length * 20, speakingMin),
            speakingMax
          )

          setTimeout(() => {
            addMessage(data.data.message)
            setCancelAnswers(false)
            setIsLoading(false)
            setIsSpeaking(true)

            setTimeout(() => {
              setIsSpeaking(false)
            }, speakTimeout)
          }, loadTimeout)
        }
      },
    }
  )

  const { mutate: sendFeedback } = useMutation((feedback: number[]) => {
    return apiClient.post<SendFeedbackPayload, SendFeedbackResponse>(
      '/feedback',
      {
        param1: feedback[0],
        param2: feedback[1],
        param3: feedback[2],
        sessionId,
      }
    )
  })

  const { mutate: deleteDialog } = useMutation(
    () => {
      return apiClient.post<DeleteDialogPayload, DeleteDialogResponse>(
        '/deleteDialog',
        { sessionId }
      )
    },
    {
      onSuccess: () => {
        clearDialog()
      },
    }
  )

  const getReport = async () => {
    const response = await apiClient.post<GetReportPayload, GetReportResponse>(
      '/report',
      { reportId }
    )
    return response.data
  }

  const sendMessage = useCallback(
    (answer: string) => {
      addMessage({
        type: 'answer',
        from: 'user',
        text: answer,
        time: new Date(),
      })
      setIsLoading(true)
      submitMessage(answer)
    },
    [addMessage, submitMessage, setIsLoading]
  )

  return {
    messages,
    sendMessage,
    sendFeedback,
    getReport,
    deleteDialog,
    isLoading: mutationLoading || isLoading,
    setIsLoading,
    isTyping,
    setIsTyping,
    isSpeaking,
    cancelAnswers,
    setCancelAnswers,
    showMenu,
    setShowMenu,
    menuPage,
    setMenuPage,
    showPrompt,
    setShowPrompt,
    showSpinner,
    setShowSpinner,
    answers,
    avatarState,
    setAvatarState,
    reportId,
    sessionId,
  }
}

export default useDialog

type SendMessagePayload = {
  answer: string
  dialogContext: number[]
  sessionId: string
}

type SendMessageResponse = AxiosResponse<{
  message: Message
  dialogContext: number[]
  reportId?: string
  sessionId: string
}>

type DeleteDialogPayload = {
  sessionId: string
}

type DeleteDialogResponse = AxiosResponse<{}>

type SendFeedbackPayload = {
  param1: string
  param2: string
  param3: string
  sessionId: string
}

type SendFeedbackResponse = AxiosResponse<{}>

type GetReportPayload = {
  id: string
}

type GetReportResponse = AxiosResponse<{
  texts: string[]
}>

import { FC } from 'react'

import { Message } from 'store'
import styled from 'styled-components'

import Avatar from 'components/Avatar'

import Attachment from './Attachment'

type Props = {
  message: Message
  last: boolean
}

const BotMessage: FC<Props> = ({ message, last }) => {
  return (
    <>
      <Wrapper>
        <AvatarWrapper>
          <Avatar state="spoken" />
        </AvatarWrapper>

        <MessageContainer>
          <MessageWrapper>{message.text}</MessageWrapper>
          <>
            {message.attachments &&
              message.attachments.map((a, index) => (
                <Attachment attachment={a} key={index} />
              ))}
          </>
        </MessageContainer>
      </Wrapper>
    </>
  )
}

export default BotMessage

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  padding-left: 2.625rem;
  margin: 0.938rem 0;
`

const MessageContainer = styled.div`
  align-self: flex-start;
  max-width: 500px;
  min-width: 50px;
  position: relative;
`

const MessageWrapper = styled.div`
  padding: 1.1875rem 1.75rem;
  background-color: ${({ theme }) => theme.colors.tones.light};
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 1.875rem;
  border-bottom-left-radius: 1.875rem;
  border-bottom-right-radius: 1.875rem;
`

const AvatarWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;

  div {
    overflow: visible;
  }
`

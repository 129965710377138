import { FC } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

import media from 'utils/MediaQueries'

import useDialog from 'api/useDialog'

type Props = { show?: boolean }

const Spinner: FC<Props> = ({ show }) => {
  const { showSpinner } = useDialog()

  return (
    <AnimatePresence>
      {(showSpinner || show) && (
        <Wrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <SpinnerAnimation>
            {Array(3)
              .fill(null)
              .map((val, index) => {
                return (
                  <Dot
                    key={`spinner-dot-${index}`}
                    animate={{
                      y: [0, -10, 0],
                    }}
                    transition={{
                      duration: 1.2,
                      delay: 0.3 * index,
                      repeat: Infinity,
                    }}
                  />
                )
              })}
          </SpinnerAnimation>
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

export default Spinner

const Wrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;
`

const SpinnerAnimation = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  ${media.greaterThan('mobile')`
    gap: 10px;
  `}
`

const Dot = styled(motion.div)`
  content: '';
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};

  ${media.greaterThan('mobile')`
    width: 30px;
    height: 30px;
  `}
`

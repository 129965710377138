const theme = {
  colors: {
    primary: '#2A928D',
    primaryDark: '#257E7A',
    primaryLight: '#32AEA8',
    secondary: '#001966',
    secondaryDark: '#001452',
    secondaryLight: '#001F7A',
    tones: {
      light: '#F5F5F5',
      medium: '#DCDCDC',
      dark: '#AAAAAA',
      darker: '#333',
    },
    menu: '#F0F0F0',
    text: '#000',
  },
}

export default theme

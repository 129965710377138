import { FC } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

import useDialog from 'api/useDialog'

import Prompt from 'components/Prompt'

import { TextAssignment } from 'api/useText'
import media from '../../utils/MediaQueries'
import MenuPage from './Page'
import MenuToggle from './Toggle'

type Props = {}

export type MenuItemPage = {
  title: string
  type?: string
  assignment?: TextAssignment
}
export const MenuItems: Record<string, MenuItemPage> = {
  about: {
    title: 'Om <strong>VIGO</strong>',
    type: 'text',
    assignment: 'ABOUT',
  },
  terms: {
    title: 'Betingelser',
    type: 'text',
    assignment: 'TERMS',
  },
  privacy: {
    title: 'Privatlivspolitik',
    type: 'text',
    assignment: 'PRIVACY_POLICY',
  },
  feedback: {
    title: 'Feedback',
    type: 'feedback',
  },
  'delete-dialog': {
    title: 'Slet samtale',
  },
  personal: {
    title: 'Relevant indhold til dig',
    type: 'personal',
  },
}

const Menu: FC<Props> = () => {
  const {
    deleteDialog,
    showMenu,
    setShowMenu,
    menuPage,
    setMenuPage,
    setShowPrompt,
    setShowSpinner,
    reportId,
    sessionId,
  } = useDialog()

  const links = [
    {
      title: MenuItems['about'].title,
      onClick: () => {
        setMenuPage('about')
      },
    },
    {
      title: MenuItems['terms'].title,
      onClick: () => {
        setMenuPage('terms')
      },
    },
    {
      title: MenuItems['privacy'].title,
      onClick: () => {
        setMenuPage('privacy')
      },
    },
    {
      title: MenuItems['feedback'].title,
      onClick: () => {
        setMenuPage('feedback')
      },
    },
  ]

  if (sessionId) {
    links.push({
      title: MenuItems['delete-dialog'].title,
      onClick: () => {
        setShowMenu(false)
        setMenuPage(null)
        setShowPrompt('delete-dialog')
      },
    })
  }

  if (reportId) {
    links.push({
      title: MenuItems['personal'].title,
      onClick: () => {
        setMenuPage('personal')
      },
    })
  }

  return (
    <>
      <AnimatePresence initial={false}>
        {showMenu && (
          <>
            <Wrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <MenuWrapper>
                {links.map((item, index) => {
                  return (
                    <MenuItemWrapper
                      initial={{
                        x: -50,
                        opacity: 0,
                      }}
                      animate={{
                        x: 0,
                        opacity: 1,
                        transition: {
                          duration: 1,
                          delay: index * 0.1,
                          ease: 'anticipate',
                        },
                      }}
                      key={`menuitem-${index}`}
                    >
                      <MenuItem
                        dangerouslySetInnerHTML={{ __html: item.title }}
                        onClick={(e) => {
                          e.preventDefault()
                          item.onClick()
                        }}
                        href="#"
                      />
                    </MenuItemWrapper>
                  )
                })}
              </MenuWrapper>
            </Wrapper>
          </>
        )}
      </AnimatePresence>
      <AnimatePresence initial={false}>
        {menuPage && <MenuPage page={MenuItems[menuPage]} />}
      </AnimatePresence>
      <MenuToggle />
      <Prompt
        id={'delete-dialog'}
        title={'Slet samtale'}
        summary={
          'Denne handling sletter den igangværende samtale, bekræft venligst.'
        }
        buttons={[
          {
            text: 'Fortryd',
            style: 'secondary',
            onClick: () => {
              setShowPrompt(null)
            },
          },
          {
            text: 'Slet samtale',
            onClick: () => {
              setShowSpinner(true)
              deleteDialog(undefined, {
                onSuccess: () => {
                  setShowSpinner(false)
                  setShowPrompt(null)
                },
                onError: () => {
                  setShowSpinner(false)
                  setShowPrompt(null)
                },
              })
            },
          },
        ]}
        width={450}
      />
    </>
  )
}

export default Menu

const Wrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;

  background-color: #fff;
  border-radius: 30px;
`

const MenuWrapper = styled.div`
  flex: 1;

  padding: 0 50px;

  transition: padding 0.5s;

  ${media.greaterThan('mobile')`
    padding: 0 142px;
  `}
`

const MenuItemWrapper = styled(motion.div)``

const MenuItem = styled.a`
  all: unset;
  cursor: pointer;
  display: inline-block;
  font-size: 28px;
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.secondary};

  strong {
    font-weight: 700;
    font-style: italic;
  }
`
